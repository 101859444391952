import * as autocomplete from "./autocomplete";
import * as correctionRequest from "./correctionRequest";
import * as deviceTypes from "./deviceTypes";
import * as documentArchiveCategories from "./documentArchiveCategories";
import * as documentArchiveTypes from "./documentArchiveTypes";
import * as employeeProcedures from "./employeeProcedures";
import * as fixedAssets from "./fixedAssets";
import * as priceLists from "./priceLists";
import * as procedures from "./procedures";
import * as productInits from "./productInits";
import * as products from "./products";
import * as promotionCreators from "./promotionCreators";

const operations = {
    autocomplete,
    correctionRequest,
    documentArchiveCategories,
    documentArchiveTypes,
    employeeProcedures,
    procedures,
    deviceTypes,
    fixedAssets,
    promotionCreators,
    priceLists,
    products,
    productInits,
};

export default operations;
