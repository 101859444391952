import { FieldPath, FieldValues } from "react-hook-form";
import { FormControlProps } from "./types";
import { SelectProps } from "control-types";
import { Select } from "components-ts/controls";
import { default as Controller } from "./Controller";

type Props<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> = FormControlProps<
    string[],
    TFieldValues,
    TName
> &
    Omit<SelectProps, "label" | "onChange" | "value"> & {
        wrapper?: (props: SelectProps) => React.ReactElement<SelectProps>;
        onCustomChange?: (value: (string | number)[] | string | number | undefined, name: string) => void;
        subpropertyName?: string;
    };

const FormSelect = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
    name,
    label,
    defaultValue,
    items,
    multiple,
    hideSelectAll,
    disableEmpty,
    emptyValue,
    width,
    readOnly,
    useGridItem,
    size = "small",
    wrapper,
    onCustomChange,
    subpropertyName,
}: Props<TFieldValues, TName>): JSX.Element => {
    return (
        <Controller
            name={name}
            render={({ field: { ref, value, onChange, disabled: _, name, onBlur }, fieldState: { error } }) => {
                if (wrapper) {
                    return wrapper({
                        onBlur,
                        onChange: (newValue: (string | number)[] | string | number | undefined) => {
                            onChange(newValue);
                        },
                        label,
                        error: {
                            hasError: Boolean(error),
                            message: error?.message,
                        },
                        items,
                        multiple,
                        value,
                        disableEmpty,
                        size,
                        readOnly,
                        name,
                    });
                } else if (multiple) {
                    return (
                        <Select
                            ref={ref}
                            label={label}
                            error={{
                                hasError: Boolean(error),
                                message: error?.message,
                            }}
                            multiple
                            hideSelectAll={hideSelectAll}
                            onBlur={onBlur}
                            onChange={(value) => {
                                if (subpropertyName !== undefined) {
                                    onChange(value?.map((v) => ({ [subpropertyName]: v })));
                                } else {
                                    onChange(value ?? []);
                                }

                                if (onCustomChange) {
                                    onCustomChange(value ?? [], name);
                                }
                            }}
                            items={items}
                            value={
                                Array.isArray(value)
                                    ? (value || []).map((v) => {
                                          if (subpropertyName) {
                                              return v[subpropertyName];
                                          } else {
                                              return v;
                                          }
                                      })
                                    : [value]
                            }
                            disableEmpty={disableEmpty}
                            readOnly={readOnly}
                            name={name}
                            size={size}
                        />
                    );
                } else {
                    return (
                        <Select
                            ref={ref}
                            label={label}
                            error={{
                                hasError: Boolean(error),
                                message: error?.message,
                            }}
                            onBlur={onBlur}
                            onChange={(value) => {
                                onChange(value);

                                if (onCustomChange) {
                                    onCustomChange(value, name);
                                }
                            }}
                            items={items}
                            value={value}
                            disableEmpty={disableEmpty}
                            readOnly={readOnly}
                            name={name}
                            size={size}
                        />
                    );
                }
            }}
            defaultValue={defaultValue}
            transform={(val) => (val !== undefined && val !== null && val !== "" ? val : emptyValue)}
            width={width}
            useGridItem={useGridItem}
        />
    );
};

export default FormSelect;
