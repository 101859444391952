import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material";
import { alpha } from "@mui/material/styles";

export type Color = "primary" | "secondary" | "error" | "warning" | "info" | "success";

const useButtonStyles = makeStyles<{ color: Color }>()(
  (theme: Theme, { color }) => ({
    root: {
      color: theme.palette[color].contrastText,
      "&.Mui-selected": {
        backgroundColor: theme.palette[color].dark,
        color: theme.palette[color].contrastText,
        "&:hover": {
          backgroundColor: theme.palette[color].dark,
        },
      },
    },
    contained: {
      backgroundColor: theme.palette[color].main,
      "&:hover": {
        backgroundColor: theme.palette[color].dark,
      },
      "&.Mui-selected": {
        backgroundColor: theme.palette[color].dark,
      },
    },
    outlined: {
      borderColor: theme.palette[color].main,
      color: theme.palette[color].main,
      "&:hover": {
        backgroundColor: alpha(theme.palette[color].main, 0.08),
        borderColor: theme.palette[color].main,
      },
      "&.Mui-selected": {
        backgroundColor: alpha(theme.palette[color].main, 0.16),
        borderColor: theme.palette[color].main,
        color: theme.palette[color].main,
      },
    },
    text: {
      color: theme.palette[color].main,
      "&:hover": {
        backgroundColor: alpha(theme.palette[color].main, 0.08),
      },
      "&.Mui-selected": {
        backgroundColor: alpha(theme.palette[color].main, 0.16),
        color: theme.palette[color].main,
      },
    },
  })
);

export default useButtonStyles;
