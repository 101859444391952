import React, { forwardRef } from "react";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { DatePickerProps } from "control-types";
import FormHelperText from "components-ts/controls/inputs/FormHelperText";
import { TextWithLabel } from "components-ts/text";

const DatePicker: React.FC<DatePickerProps> = forwardRef(
    (
        {
            error,
            label,
            value,
            onChange,
            onBlur,
            classes,
            format = "YYYY-MM-DD",
            disableFuture,
            disablePast,
            slotProps,
            slots,
            readOnly,
            minDate,
            maxDate,
            name,
            required,
        },
        ref
    ) => {
        if (readOnly) {
            return (
                <TextWithLabel
                    value={value ? (moment.isMoment(value) ? value : moment(value)).format(format || "YYYY-MM-DD") : undefined}
                    label={label}
                    error={error}
                />
            );
        }

        return (
            <>
                <MuiDatePicker
                    onChange={(newValue, _) => {
                        onChange(
                            newValue !== null
                                ? (moment.isMoment(newValue) ? newValue : moment(newValue)).local(true).startOf("day").toDate()
                                : undefined
                        );
                    }}
                    onClose={onBlur}
                    name={name}
                    format={format}
                    value={value ? (moment.isMoment(value) ? value : moment(value)).local(true).startOf("day") : null}
                    label={label}
                    slotProps={{
                        textField: {
                            error: error?.hasError,
                            variant: "standard",
                            fullWidth: true,
                            classes: classes,
                            onBlur: onBlur,
                            required: required,
                        },
                        field: {
                            clearable: true,
                            onClear: () => onChange(undefined),
                        },
                        ...slotProps,
                    }}
                    disableFuture={disableFuture ?? false}
                    disablePast={disablePast ?? false}
                    slots={slots}
                    readOnly={readOnly}
                    minDate={minDate}
                    maxDate={maxDate}
                    ref={ref}
                />
                <FormHelperText error={error} />
            </>
        );
    }
);

export default DatePicker;
