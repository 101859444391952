import { makeStyles } from "tss-react/mui";
import React, { forwardRef, useCallback, useMemo } from "react";
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select as MuiSelect } from "@mui/material";
import { SelectProps } from "control-types";
import { useTranslation } from "utils-ts/hooks";
import FormHelperText from "components-ts/controls/inputs/FormHelperText";
import { TextWithLabel } from "components-ts/text";

const useStyles = makeStyles<{ size: "large" | "medium" | "small" | number }>()((_theme, { size }) => ({
    formControl: {
        minWidth: typeof size === "number" ? size - 5 : size === "large" ? 200 : size === "medium" ? 150 : 100,
    },
    menuItem: {
        whiteSpace: "break-spaces",
        wordBreak: "break-word",
    },
}));

const allValue = "ALL";
const selectEmptyValue = "";

const Select: React.FC<SelectProps> = forwardRef(
    (
        {
            items,
            error,
            label,
            value,
            disableEmpty,
            multiple,
            onBlur,
            onChange,
            classes,
            readOnly,
            hideSelectAll = false,
            size = "large",
            name,
            required,
        },
        ref
    ) => {
        const { classes: baseClasses } = useStyles({ size });
        const { t } = useTranslation();
        const selectItems = useMemo(() => {
            let selectItems = items.map((i) => {
                return typeof i === "string" || typeof i === "number"
                    ? {
                          name: i,
                          value: i,
                          disabled: false,
                      }
                    : {
                          name: typeof i.name !== "string" ? t(i.name) : i.name,
                          value: i.value,
                          disabled: i.disabled,
                      };
            });

            if (!disableEmpty) {
                selectItems = [{ value: undefined, name: t("emptySelectItem"), disabled: false }, ...selectItems];
            }

            if (multiple && !hideSelectAll) {
                selectItems = [{ value: allValue, name: t("all"), disabled: false }, ...selectItems];
            }

            return selectItems;
        }, [items, t, disableEmpty]);

        const menuItems = useCallback(() => {
            return selectItems.map((i) => {
                if (multiple) {
                    return (
                        <MenuItem
                            key={i.value || "none"}
                            value={i.value}
                            disabled={i.disabled}
                        >
                            <Checkbox
                                checked={
                                    value !== undefined &&
                                    (value.some((v) => v == i.value) ||
                                        (i.value === allValue &&
                                            selectItems.filter((v) => v.value !== allValue).every((si) => value.some((v) => v === si.value))))
                                }
                            />
                            <ListItemText primary={i.name} />
                        </MenuItem>
                    );
                } else {
                    return (
                        <MenuItem
                            key={i.value || "none"}
                            value={i.value}
                            disabled={i.disabled}
                        >
                            {i.name}
                        </MenuItem>
                    );
                }
            });
        }, [value]);

        if (readOnly) {
            return (
                <TextWithLabel
                    label={label}
                    value={value?.toString()}
                    items={items.map((i) => {
                        return typeof i === "string" || typeof i === "number"
                            ? {
                                  name: i?.toString() || "",
                                  value: i,
                                  disabled: false,
                              }
                            : {
                                  name: typeof i.name !== "string" ? t(i.name) : i.name,
                                  value: i.value,
                                  disabled: i.disabled,
                              };
                    })}
                    multiple={multiple}
                    error={error}
                />
            );
        }

        const props = {
            children: menuItems(),
            className: baseClasses.formControl,
            classes,
            fullWidth: true,
            label,
            labelId: `${label}-label`,
            onBlur,
            inputRef: ref,
            required: required,
        };

        return (
            <>
                <FormControl
                    fullWidth
                    error={error?.hasError}
                    variant="standard"
                >
                    <InputLabel
                        id={`${label}-label`}
                        classes={classes}
                        required={required}
                    >
                        {label}
                    </InputLabel>
                    {multiple ? (
                        <MuiSelect<(string | number)[] | undefined>
                            name={name}
                            value={value ?? []}
                            onChange={(event) => {
                                const {
                                    target: { value: newValue },
                                } = event;

                                if (newValue === allValue || (Array.isArray(newValue) && newValue.some((v) => v === allValue))) {
                                    const itemsValue = items
                                        .filter((i) => (typeof i === "object" ? i.disabled !== true : Boolean(i)))
                                        .map((i) => (typeof i === "string" || typeof i === "number" ? i : i.value ?? ""));

                                    if (itemsValue.every((iv) => value?.some((v) => v === iv))) {
                                        onChange(undefined);
                                    } else {
                                        onChange(itemsValue);
                                    }
                                } else if (
                                    (Array.isArray(newValue) && newValue.length === 0) ||
                                    typeof newValue === "undefined" ||
                                    newValue === selectEmptyValue
                                ) {
                                    onChange(undefined);
                                } else {
                                    onChange(Array.isArray(newValue) ? newValue.filter((nv) => nv !== "" && nv !== undefined) : [newValue]);
                                }
                            }}
                            multiple={multiple}
                            renderValue={(selected) => {
                                if (selected === undefined) {
                                    return undefined;
                                }

                                if (
                                    items.every((i) =>
                                        typeof i === "object" && "value" in i ? selected.some((s) => s === i.value) : selected.some((s) => s === i)
                                    )
                                ) {
                                    return t("all");
                                }

                                const hasNames = items.some((i) => typeof i === "object" && "name" in i);
                                if (hasNames) {
                                    const selectedItem = items.filter(
                                        (i) => typeof i === "object" && "value" in i && selected.some((s) => s === i.value)
                                    );
                                    const names = selectedItem.map((s) =>
                                        typeof s === "object" && "name" in s ? (typeof s.name !== "string" ? t(s.name) : s.name) : s
                                    );

                                    return names.join(", ");
                                }

                                return selected.join(", ");
                            }}
                            SelectDisplayProps={{ style: { wordBreak: "break-word", whiteSpace: "break-spaces" } }}
                            {...props}
                        />
                    ) : (
                        <MuiSelect<string | number | undefined>
                            name={name}
                            value={Array.isArray(value) ? value.join(",") : value ?? ""}
                            onChange={(event) => {
                                const {
                                    target: { value },
                                } = event;

                                onChange(value);
                            }}
                            renderValue={(selected) => {
                                const hasNames = items.some((i) => typeof i === "object" && "name" in i);
                                if (hasNames) {
                                    const selectedItem = items.find((i) => typeof i === "object" && "value" in i && i.value === selected);
                                    if (typeof selectedItem === "object" && "name" in selectedItem) {
                                        return typeof selectedItem.name !== "string" ? t(selectedItem.name) : selectedItem.name;
                                    }
                                }

                                return selected;
                            }}
                            SelectDisplayProps={{ style: { wordBreak: "break-word", whiteSpace: "break-spaces" } }}
                            {...props}
                        />
                    )}
                </FormControl>
                <FormHelperText error={error} />
            </>
        );
    }
);

export default Select;
