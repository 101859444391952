const routing = {
    users: "users",
    shopConfiguration: "shopConfiguration",
    crawler: "crawler",
    productScanningStatus: "productScanningStatus",
    externalProduct: "externalProduct",
    shopAuthorization: "shopAuthorization",
    noData: "noData",
    contactTypes: "contactTypes",
    analyticGroupsList: "analyticGroupsList",
    vatCorrections: "vatCorrections",
    balanceDebts: "balanceDebts",
    bridge: "bridge",
    marketingTools: "marketingTools",
    promotionPeriods: "promotionPeriods",
    toolkits: "toolkits",
    reservationKits: "reservationKits",
    closedAccountingPeriods: "closedAccountingPeriods",
    deliveryWindows: "deliveryWindows",
    cms: "cms",
    campaigns: "campaigns",
    contests: "contests",
    rules: "rules",
    content: "content",
    initProducts: "initProducts",
    products: "products",
    categoryTrees: "categoryTrees",
    categories: "categories",
    brands: "brands",
    alerts: "alerts",
    customers: "customers",
    customerDepositsImport: "customerDepositsImport",
    dashboard: "dashboard",
    deliverycomplaints: "deliverycomplaints",
    dictionaries: "dictionaries",
    documentCategories: "documentCategories",
    documentTypes: "documentTypes",
    costReasons: "costReasons",
    cnStakes: "cnStakes",
    documentDefinitions: "documentDefinitions",
    documentArchive: "documentArchive",
    search: "search",
    documentArchiveAccess: "documentArchiveAccess",
    dotpayPayouts: "dotpayPayouts",
    emailsList: "emailsList",
    subsidiaryGains: "subsidiaryGains",
    offers: "offers",
    productPreview: "productPreview",
    feed: "feed",
    productContent: "productContent",
    discounts: "discounts",
    promotions: "promotions",
    gifts: "gifts",
    contracts: "contracts",
    rewards: "rewards",
    orderSearch: "orderSearch",
    orderAlerts: "orderAlerts",
    paymentTransfers: "paymentTransfers",
    priceList: "priceList",
    limitsRestrictions: "limitsRestrictions",
    waterLimits: "waterLimits",
    productLimits: "productLimits",
    productRestrictions: "productRestrictions",
    productRations: "productRations",
    replacements: "replacements",
    reports: "reports",
    subsidiaryGainReport: "subsidiaryGainReport",
    crawlerLinkingReport: "crawlerLinkingReport",
    correctionRequestRetro: "correctionRequestRetro",
    correctionRequestRefundation: "correctionRequestRefundation",
    reviews: "reviews",
    phraseBoost: "phraseBoost",
    keywords: "keywords",
    synonymsPl: "synonymsPl",
    synonymsEn: "synonymsEn",
    vendors: "vendors",
    vendorOperations: "vendorOperations",
    decisions: "decisions",
    vouchers: "vouchers",
    generators: "generators",
    batches: "batches",
    jobPositions: "jobPositions",
    invoiceIssueReasons: "invoiceIssueReasons",
    proceduresList: "proceduresList",
    dynamicSegmentList: "dynamicSegmentList",
    administration: "administration",
    procedures: "procedures",
    productSubstitute: "productSubstitute",
    productsSeo: "productsSeo",
    roles: "roles",
    structure: "structure",
    procedureCategories: "procedureCategories",
    companyStructure: "companyStructure",
    deviceTypes: "deviceTypes",
    devicesList: "devicesList",
    fixedAssets: "fixedAssets",
    devicesAssignmentList: "devicesAssignmentList",
    workstationList: "workstationList",
    assignAssetNumbers: "assignAssetNumbers",
    imports: "imports",
    retentionManagers: "retentionManagers",
    userBalanceDebts: "userBalanceDebts",
    purchaseSchedules: "purchaseSchedules",
    promotionCreatorDraftsList: "promotionCreatorDraftsList",
    promotionCreatorSearch: "promotionCreatorSearch",
    correctionRequests: "correctionRequests",
} as const;

export default routing;
