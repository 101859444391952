import { styles as commonStyles } from "assets/jss/common";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { createForm } from "components/FormHelpers/FormCreator";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { Layout } from "components";
import { content } from "translations";
import { validators } from "../../validators";

const useStyles = makeStyles()({
    ...commonStyles,
    fieldsGap: {
        paddingTop: "12px",
    },
});

export const warehouses = [
    { name: "Warszawa", value: "FRI 2" },
    { name: "Wrocław", value: "WRO" },
    { name: "Poznań", value: "POZ" },
    { name: "Kraków", value: "KRA" },
    { name: "Trójmiasto", value: "GDA" },
    { name: "Bydgoszcz", value: "BYD", disabled: true },
];
const stores = {
    "FRI 2": [
        { name: "WAW", value: "WAW" },
        { name: "WA2", value: "WA2", superiorStores: ["WAW"] },
        { name: "WA3", value: "WA3" },
    ],
    "WRO": [{ name: "WRO", value: "WRO" }],
    "POZ": [{ name: "POZ", value: "POZ", replenishmentStores: ["WA3"] }],
    "KRA": [{ name: "KRA", value: "KRA" }],
    "GDA": [{ name: "GDA", value: "GDA" }],
    "BYD": [{ name: "BYD", value: "BYD", disabled: true }],
};

const VendorsPerWarehouse = ({
    selectedWarehouses = [],
    useDefaultSupplier,
    isManufacturedProduct,
    canModify,
    vendors = [],
    useCard,
    selectedStores = {},
    change,
    manufacturedWarehouses = [],
}) => {
    const { classes } = useStyles();
    const { t } = useTranslation(["content", "common"]);
    const activeVendors = vendors.filter((x) => x.isActive && x.isGoodsVendor).map((x) => x.vendorId);

    let fields = [
        {
            component: "switch",
            label: t(content.useDefaultSupplier),
            name: "useDefaultSupplier",
            readOnly: !canModify,
        },
    ];

    if (useDefaultSupplier) {
        fields = [
            ...fields,
            {
                component: "autoComplete",
                type: "text",
                label: t(content.defaultSupplier),
                name: "defaultSupplier",
                options: activeVendors,
                getOptionLabel: (item) => {
                    const vendor = vendors.find((x) => x.vendorId === item);
                    if (vendor) {
                        return `${vendor.name}(${vendor.vendorId})`;
                    } else {
                        return "";
                    }
                },
                readOnly: !canModify,
                validate: validators.requiredNotEmpty,
            },
        ];
    }

    const warehouseRows = warehouses
        .filter((o) => {
            return (
                !(
                    o.disabled &&
                    (!selectedWarehouses.some((p) => p == o.value) || selectedStores[`warehouse | ${o.value} | store`] == undefined) &&
                    !manufacturedWarehouses.some((p) => p == o.value)
                ) || !canModify
            );
        })
        .map((x) => {
            let fields = [
                {
                    component: "textField",
                    label: t(content.warehouse),
                    name: `warehouse|${x.value}|name`,
                    readonly: true,
                    defaultValue: x.value,
                    validate: validators.required,
                },
                {
                    component: "switch",
                    label: t(content.isSold),
                    name: `warehouse|${x.value}|isSold`,
                    readOnly: !canModify,
                    onChange: (_, value) => {
                        let warehouses = manufacturedWarehouses;
                        if (!value) {
                            warehouses = warehouses.filter((w) => w !== x.value);
                            change("manufacturedWarehouses", warehouses);
                            change(`warehouse|${x.value}|isManufacturedProduct`, false);
                        }
                    },
                },
            ];

            if (selectedWarehouses.includes(x.value) && isManufacturedProduct) {
                fields = [
                    ...fields,
                    {
                        component: "switch",
                        label: t(content.isManufacturedProduct),
                        name: `warehouse|${x.value}|isManufacturedProduct`,
                        readOnly: !canModify || !isManufacturedProduct,
                        onChange: (_, value) => {
                            let warehouses = manufacturedWarehouses;
                            if (value && !warehouses.some((w) => w === x.value)) {
                                warehouses = [...warehouses, x.value];
                            } else if (!value && warehouses.some((w) => w === x.value)) {
                                warehouses = warehouses.filter((w) => w !== x.value);
                            }
                            change("manufacturedWarehouses", warehouses);
                        },
                        validate: isManufacturedProduct ? validators.atLeastOneManufacturedWarehouse : null,
                    },
                ];
            }

            const currentWarehouse = (Object.entries(selectedStores).find(([key, _]) => key.includes(x.value)) || {})[1] || [];
            const storeComponent = {
                component: "array",
                customLabels: [],
                label: t(content.stores),
                name: `warehouse|${x.value}|store`,
                readOnly: !canModify,
                validate: validators.requiredNotEmptyArray,
                disableEditing: true,
                disableColumnHeader: true,
                change,
                subComponents: [
                    {
                        component: "select",
                        label: t(content.store),
                        name: `store`,
                        dynamicItems: (index) => {
                            const otherStores = currentWarehouse?.filter((_, i) => i !== index).map((x) => x.store);
                            return stores[x.value]?.filter((x) => !otherStores.includes(x.value)) || [];
                        },
                        readOnly: !canModify,
                        validate: validators.requiredNotEmpty,
                        disableEditing: true,
                        onValueChange: (index, val) => {
                            change(`warehouse|${x.value}|store[${index}].store`, val);
                            change(`warehouse|${x.value}|store[${index}].superiorStore`, undefined);
                            change(`warehouse|${x.value}|store[${index}].replenishmentStore`, undefined);
                        },
                    },
                    {
                        component: "select",
                        label: t(content.superiorStore),
                        name: `superiorStore`,
                        dynamicItems: (index) => {
                            const currentStore = currentWarehouse?.find((_, i) => i === index)?.store;

                            const superiorStores = (stores[x.value]?.find((x) => x.value === currentStore) || {})?.superiorStores || [];

                            return stores[x.value]?.filter((s) => superiorStores.some((ss) => ss === s.value));
                        },
                        dynamicHidden: (index) => {
                            const currentStore = currentWarehouse?.find((_, i) => i === index)?.store;

                            return (stores[x.value]?.find((x) => x.value === currentStore)?.superiorStores || []).length === 0;
                        },
                        readOnly: !canModify,
                        disableEditing: true,
                    },
                    {
                        component: "select",
                        label: t(content.replenishmentStore),
                        name: `replenishmentStore`,
                        dynamicItems: (index) => {
                            const currentStore = currentWarehouse?.find((_, i) => i === index)?.store;

                            const replenishmentStores = (stores[x.value]?.find((x) => x.value === currentStore) || {})?.replenishmentStores || [];

                            return replenishmentStores.map((rs) => ({ name: rs, value: rs }));
                        },
                        dynamicHidden: (index) => {
                            const currentStore = currentWarehouse?.find((_, i) => i === index)?.store;

                            return (stores[x.value]?.find((x) => x.value === currentStore)?.replenishmentStores || []).length === 0;
                        },
                        readOnly: !canModify,
                        disableEditing: true,
                    },
                ],
            };

            if (!selectedWarehouses.includes(x.value)) {
                return fields;
            } else if (useDefaultSupplier) {
                return [...fields, storeComponent];
            } else {
                return [
                    ...fields,
                    {
                        component: "autoComplete",
                        type: "text",
                        label: t(content.supplier),
                        name: `warehouse|${x.value}|supplier`,
                        options: activeVendors,
                        getOptionLabel: (item) => {
                            const vendor = vendors.find((x) => x.vendorId === item);
                            if (vendor) {
                                return `${vendor.name}(${vendor.vendorId})`;
                            } else {
                                return "";
                            }
                        },
                        readOnly: !canModify,
                        validate: validators.requiredNotEmpty,
                    },
                    storeComponent,
                ];
            }
        });

    const body = (
        <>
            {createForm(fields, null, false, true)}
            <Layout
                isEnlarged
                headerText={t(content.warehouses)}
            >
                {warehouseRows.map((x, key) => (
                    <Card key={key}>
                        <CardBody>{createForm(x, null, false, true)}</CardBody>
                    </Card>
                ))}
            </Layout>
        </>
    );

    return useCard ? (
        <Grid
            item
            xs={12}
            sm={12}
            md={12}
        >
            <Card>
                <CardBody>
                    <div className={classes.fieldsGap}>{body}</div>
                </CardBody>
            </Card>
        </Grid>
    ) : (
        <Grid
            item
            xs={12}
            sm={12}
            md={12}
        >
            {body}
        </Grid>
    );
};

export default VendorsPerWarehouse;
